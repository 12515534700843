import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { get } from "lodash";
import request from "utils/request";

type Status = "idle" | "loading" | "success" | "error";

export interface PayloadActionGetListServer {
  region: string;
  site_name: string;
}

export interface PayloadActionListServerDetail {
  id: number;
  slug: string;
  is_proxy?: boolean;
  name: string;
  description: string;
  price: number;
  cpu: string;
  ram: string;
  ssd: string;
  bandwidth: string;
  region?: string;
  ethernet_port: string;
  ipv4: string;
  status: string;
  featured: boolean;
}

interface GetListServerState {
  listServerDetail: PayloadActionListServerDetail[];
  paramsListServerDetail: PayloadActionGetListServer;
  statusLoadListServerDetail: Status;
}

const initialState: GetListServerState = {
  listServerDetail: [],
  paramsListServerDetail: {
    region: "",
    site_name: ''
  },
  statusLoadListServerDetail: "idle",
};

export const actionGetListServer = createAsyncThunk(
  "listServer/actionGetListServer",
  async (params: PayloadActionGetListServer, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "api/plan/vps",
        method: "GET",
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "serverDetail",
  initialState,
  reducers: {
    actionResetStatus(state) {
      state.statusLoadListServerDetail = "idle";
    },
    actionResetStore() {
      return { ...initialState };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetListServer.pending, (state) => {
        state.statusLoadListServerDetail = "loading";
      })
      .addCase(actionGetListServer.fulfilled, (state, action) => {
        state.statusLoadListServerDetail = "success";
        state.listServerDetail = get(action, "payload.data").map(
          (item: PayloadActionListServerDetail) => ({ ...item })
        );
      })
      .addCase(actionGetListServer.rejected, (state) => {
        state.statusLoadListServerDetail = "error";
      });
  },
});

export const { actionResetStatus, actionResetStore } = slice.actions;

export const selectListServerDetail = (state: RootState) =>
  state.serverDetail.listServerDetail;
export const selectLoadingListServerDetail = (state: RootState) =>
  state.serverDetail.statusLoadListServerDetail;

export default slice.reducer;
