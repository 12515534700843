/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ServerCategory() {
  const navigate = useNavigate();

  return (
    <div
      className="dropdown-menu default_dd_menu hosting_dd_menu"
      aria-labelledby="navbarDropdown"
      style={{ width: "450px" }}
    >
      <div id="accordion" className="row hide_on_tablet">
        <div className="nav-item col-md-6">
          <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
            KHU VỰC VIỆT NAM
          </span>
          <a
            className="dropdown-item d-flex"
            href=""
            onClick={() => {
              navigate(URL.ListServerVN);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/vietnam.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>VPS Việt Nam (VN)</span>
          </a>
        </div>
        <div className="nav-item col-md-6">
          <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
            KHU VỰC CHÂU Á
          </span>
          <a
            className="dropdown-item d-flex"
            href=""
            onClick={() => {
              navigate(URL.ListServerSG);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/singapore.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>VPS Singapore (SG)</span>
          </a>
          <a
            className="dropdown-item pt-0 d-flex"
            href=""
            onClick={() => {
              navigate(URL.ListServerAU);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/australia.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>VPS Úc (AU)</span>
          </a>
        </div>
        <div className="nav-item col-md-6">
          <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
            KHU VỰC CHÂU ÂU
          </span>
          <a
            className="dropdown-item d-flex"
            href=""
            onClick={() => {
              navigate(URL.ListServerUK);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/united-kingdom.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>VPS Anh (UK)</span>
          </a>
          <a
            className="dropdown-item pt-0 d-flex"
            href=""
            onClick={() => {
              navigate(URL.ListServerDE);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/germany.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>VPS Đức (DE)</span>
          </a>
          <a
            className="dropdown-item pt-0 d-flex"
            href=""
            onClick={() => {
              navigate(URL.ListServerFR);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/france.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>VPS Pháp (FR)</span>
          </a>
        </div>
        <div className="nav-item col-md-6">
          <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
            KHU VỰC ÂU MỸ
          </span>
          <a
            className="dropdown-item d-flex"
            href=""
            onClick={() => {
              navigate(URL.ListServerUS);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/united-states.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>VPS Mỹ (US)</span>
          </a>
          <a
            className="dropdown-item pt-0 d-flex"
            href=""
            onClick={() => {
              navigate(URL.ListServerCA);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/canada.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>VPS Canada (CA)</span>
          </a>
        </div>
        <div className="nav-item col-md-6">
          <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
            THEO MỤC ĐÍCH
          </span>
          <a
            className="dropdown-item d-flex"
            href=""
            onClick={() => {
              navigate(URL.ListServerEU);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/european-union.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>VPS Châu Âu (PTU)</span>
          </a>
        </div>
      </div>
      <ul className="mobile_navigation">
        <a
          className="dropdown-item d-flex"
          style={{ fontSize: "14px" }}
          href=""
          onClick={() => {
            navigate(URL.ListServerVN);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/vietnam.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>VPS Việt Nam (VN)</span>
        </a>
        <a
          className="dropdown-item d-flex"
          style={{ fontSize: "14px" }}
          href=""
          onClick={() => {
            navigate(URL.ListServerSG);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/singapore.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>VPS Singapore (SG)</span>
        </a>
        <a
          className="dropdown-item pt-0 d-flex"
          style={{ fontSize: "14px" }}
          href=""
          onClick={() => {
            navigate(URL.ListServerAU);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/australia.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>VPS Úc (AU)</span>
        </a>
        <a
          className="dropdown-item d-flex"
          style={{ fontSize: "14px" }}
          href=""
          onClick={() => {
            navigate(URL.ListServerUK);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/united-kingdom.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>VPS Anh (UK)</span>
        </a>
        <a
          className="dropdown-item pt-0 d-flex"
          style={{ fontSize: "14px" }}
          href=""
          onClick={() => {
            navigate(URL.ListServerDE);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/germany.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>VPS Đức (DE)</span>
        </a>
        <a
          className="dropdown-item pt-0 d-flex"
          style={{ fontSize: "14px" }}
          href=""
          onClick={() => {
            navigate(URL.ListServerFR);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/france.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>VPS Pháp (FR)</span>
        </a>
        <a
          className="dropdown-item d-flex"
          style={{ fontSize: "14px" }}
          href=""
          onClick={() => {
            navigate(URL.ListServerUS);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/united-states.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>VPS Mỹ (US)</span>
        </a>
        <a
          className="dropdown-item pt-0 d-flex"
          style={{ fontSize: "14px" }}
          href=""
          onClick={() => {
            navigate(URL.ListServerCA);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/canada.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>VPS Canada (CA)</span>
        </a>
        <a
          className="dropdown-item d-flex"
          style={{ fontSize: "14px" }}
          href=""
          onClick={() => {
            navigate(URL.ListServerEU);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/european-union.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>VPS Châu Âu (PTU)</span>
        </a>
      </ul>
    </div>
  );
}
