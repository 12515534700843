/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";

import { useNavigate } from "react-router-dom";
import DedicatedServerCategory from "./DedicatedServerCategory";
import ServerCategory from "./ServerCategory";
import ProxyCategory from "./ProxyCategory";
import S5ProxyCategory from "./S5ProxyCategory";

export default function Header() {
  const navigate = useNavigate();

  return (
    <div id="default_header">
      {/*Navigation Bar*/}
      <header>
        <div className="bg_001144 top_bar_outer">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <ul className="top_bar_social_links">
                  <li>
                    <a href="https://www.facebook.com/Vps4vncom-105305512168987">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <ul className="top_bar_contact_list">
                  <li>
                    <i className="fas fa-phone-volume" />
                    <a href="tel:+(84)988681108">+(84)988681108</a>
                  </li>
                  <li>
                    <i className="fas fa-lock" />
                    <a href={URL.Register}>Đăng ký </a>|
                    <a href={URL.Login}> Đăng nhập</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*Header*/}
        <nav className="navbar navbar-expand-lg default_navigation">
          <div className="container header-custom">
            <a className="navbar-brand default_logo" href="/">
              <figure>
                <img
                  src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-w.png`}
                  alt=""
                />
              </figure>
            </a>
            <div className="auth-mobile">
              <a
                style={{ color: "white", fontWeight: 300 }}
                href={URL.Register}
              >
                Đăng ký |
              </a>
              <a style={{ color: "white", fontWeight: 300 }} href={URL.Login}>
                {" "}
                Đăng nhập
              </a>
            </div>
            <button
              className="navbar-toggler collapsed mobile_toggle_btn"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse default_navigation_box"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav inner_default_navigation ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Trang chủ
                  </a>
                </li>
                <li className="nav-item dropdown default_header_dd">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    VPS
                  </a>
                  <ServerCategory />
                </li>
                <li className="nav-item dropdown default_header_dd">
                  <a
                    className="nav-link dropdown-toggle"
                    href=""
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dedicated Server
                  </a>
                  <DedicatedServerCategory />
                </li>
                <li className="nav-item dropdown default_header_dd">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown11"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Proxy
                  </a>
                  <ProxyCategory />
                </li>
                <li className="nav-item dropdown default_header_dd">
                  <a
                    className="nav-link dropdown-toggle"
                    href=""
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    S5 Proxy
                  </a>
                  <S5ProxyCategory />
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href=""
                    onClick={() => {
                      navigate(URL.Agreement);
                    }}
                  >
                    Điều khoản
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href=""
                    onClick={() => {
                      navigate(URL.SLA);
                    }}
                  >
                    SLA
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href=""
                    onClick={() => {
                      navigate(URL.Contact);
                    }}
                  >
                    Liên hệ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/*Ends Here*/}
      </header>
      {/*Ends Here*/}
    </div>
  );
}
